<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('license_management.application') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
         <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
            label-for="company_division_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.division') }}
              </template>
              <b-form-select
                plain
                id="company_division_id"
                :options="divisionList"
                v-model="search.company_division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group
                label-for="company_district_id"
              >
              <template v-slot:label>
                {{ $t('globalTrans.district') }}
              </template>
              <b-form-select
                plain
                id="company_district_id"
                :options="districtList"
                v-model="search.company_district_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group
                label-for="company_upazilla_id"
              >
              <template v-slot:label>
                {{ $t('globalTrans.upazila') }}
              </template>
              <b-form-select
                plain
                id="company_upazilla_id"
                :options="upazilaList"
                v-model="search.company_upazilla_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                label-for="application_id"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.application_id') }}
                </template>
                <b-form-input
                    id="application_id"
                    v-model="search.application_id"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="9">
          </b-col>
          <b-col sm="3" style="text-align: right;">
                <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
            </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('license_management.application_list') }}</h4>
      </template>
      <template v-slot:headerAction>
      <router-link class="btn-add" to="application-entry-form"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(application_id)="data">
                    {{ $n(data.item.application_id, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(date)="data">
                    {{ data.item.created_at | dateFormat }}
                  </template>
                  <template v-slot:cell(applicant_name)="data">
                    {{ data.item.details.applicant_name }}
                  </template>
                  <template v-slot:cell(applicant_name_bn)="data">
                    {{ data.item.details.applicant_name_bn }}
                  </template>
                  <template v-slot:cell(mobile)="data">
                    {{ data.item.details.mobile | mobileNumber }}
                  </template>
                  <template v-slot:cell(district_id)="data">
                    {{ getDistrictName(data.item.details.company_district_id) }}
                  </template>
                  <template v-slot:cell(type)="data">
                    {{ getTypeName(data.item.type) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span v-if="data.item.save_status === 1" class="badge badge-primary">{{$t('dealer.draft_status')}} </span>
                    <span v-else class="badge badge-primary">{{ $t('license_management.final_save') }} </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <router-link class="action-btn btn-warning view" :title="$t('globalTrans.view')" :to="`/license-registration-service/license-management/application-show/${data.item.id}`"><i class="ri-eye-fill"></i></router-link>
                    <button v-if="data.item.save_status === 1" @click="editForm(data.item)" style="border-radius: 40px" size="sm" :title="$t('globalTrans.edit')" class="action-btn edit"><i class="ri-pencil-fill"></i></button>
                    <button v-if="data.item.save_status === 2" v-b-modal.payment @click="paymentData(data.item)" class="btn btn-sm btn-warning" :title="$t('dealer.pay')">{{ $t('dealer.pay') }}</button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="payment" size="lg" :title="$t('dealer.payment')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Payment :application="appDetail" />
    </b-modal>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { tcbAdminApplicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import userTypeModule from '@/utils/userType'
import Payment from '../../../../../modules/external-user-service/views/dealer-panel/pages/application/Payment.vue'
import { EventBus } from '@/EventBusLayout'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Payment
  },
  data () {
    return {
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      appDetail: '',
      search: {
       application_id: '',
       company_division_id: 0,
       company_district_id: 0,
       company_upazilla_id: 0,
       limit: 10
      },
      districtList: [],
      upazilaList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'dealer.applicant_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'dealer.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'globalTrans.district', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'globalTrans.type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '10%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 8, thStyle: { width: '10%' } }
      ],
      actions: {
        edit: false,
        details: false,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
    EventBus.$on('offlinePaymentSubmit', this.loadData)
  },
  mounted () {
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.company_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.company_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazillaList(newVal)
    }
  },
  computed: {
    loggedUserPrivilege: function () {
      return this.$store.state.BazarMonitoringService.commonObj.loggedUserPrivilege
    },
    addressCustomUser: function () {
      return this.$store.state.BazarMonitoringService.commonObj.addressCustomUser
    },
    areaTypeList: function () {
      const list = this.$store.state.commonObj.areaTypeList
      return list.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'date' },
          { key: 'application_id' },
          { key: 'applicant_name_bn' },
          { key: 'mobile' },
          { key: 'district_id' },
          { key: 'type' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'date' },
          { key: 'application_id' },
          { key: 'applicant_name' },
          { key: 'mobile' },
          { key: 'district_id' },
          { key: 'type' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('orgProfile.org_profile') + ' ' + this.$t('globalTrans.entry') : this.$t('orgProfile.org_profile') + ' ' + this.$t('globalTrans.modify')
    }
  },
  methods: {
    getDistrictList (divisionId) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === divisionId)
    },
    getUpazillaList (districtId) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === districtId)
    },
    clickForward () {
      const appIds = this.applications.map(item => {
        return item.id
      })
      this.$router.push({
        path: '/license-registration-service/license-management/forward',
        query: { applications: JSON.stringify(appIds) }
      })
    },
    checkApplication (value) {
      if (value.select) {
        this.applications.push({
          application_id: value.application_id,
          id: value.id
        })
      } else {
        this.applications = this.applications.filter(item => {
          return item.application_id !== value.application_id
        })
      }
    },
    editMethodInParent (item, type) {
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, {
         page: this.pagination.currentPage,
         per_page: this.search.limit,
         is_office_user: userTypeModule.userType('is_office_user')
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, tcbAdminApplicationList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index, select: 0 })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictName (id) {
      const data = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getTypeName (id) {
      const data = this.$store.state.LicenseRegistrationService.commonObj.applicationTypeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    editForm (item) {
      this.appDetail = item
      this.$router.push({ path: '/license-registration-service/license-management/application-entry-form', query: { editStatus: true, appDetail: this.appDetail } })
    },
    paymentData (item) {
      this.appDetail = item
    }
  }
}
</script>

<style>
.btn-success {
  background-color: var(--success) !important;
  border-color: var(--success);
}
</style>
